$blue: #0bdefa;
.form__group {
  position: relative;
  margin: 20px 0;
}

.form__label {
  position: absolute;
  color: gray;
  transition: 0.2s;
}

.form__field {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 3px;
    left: 10px;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
  }
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: $blue;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1.5px;
  border-color: $blue;
}

.form__field:not(:empty):focus {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: $blue;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1.5px;
  border-color: $blue;
}

.form__field:not(:empty):not(:focus) {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1px;
  border-color: gray;
}

/*datepicker*/
.react-datepicker__input-container {
  position: relative;
  margin: 0;
}

.form__label-datepicker {
  position: absolute;
  color: gray;
  transition: 0.2s;
}

//1
.form__field-datepicker:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label-datepicker {
    cursor: text;
    top: 6px;
    left: 10px;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
  }
}

//2
.form__field-datepicker:focus {
  ~ .form__label-datepicker {
    position: absolute;
    background-color: white;
    color: $blue;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 4;
    transition: 0.2s;
  }
  &::placeholder {
    color: gray;
  }
  border-width: 1.5px;
  border-radius: 3px;
  border-color: $blue;
}

.form__field-datepicker{
  ~ .form__label-datepicker {
    position: absolute;
    background-color: white;
    color: grey;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 4;
    transition: 0.2s;
  }
  border-width: 1px;
  border-color: gray;
}

/*Amount*/
.form__field.amount:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 5px;
    left: 10px;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
  }
}

.form__field.amount:focus {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: $blue;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1.5px;
  border-color: $blue;
}

.form__field.amount {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1px;
  border-color: gray;
}

/*Amount-Family*/
.form__field.amount-family:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 3px;
    left: 7px;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
  }
}
.form__field.amount-family:empty:not(:focus):disabled {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 3px;
    left: 7px;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
    background-color: rgba(0,0,0,0);
  }
}

.form__field.amount-family:focus {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: $blue;
    height: max-content;
    top: -9px;
    left: 5px;
    padding: 0 3px 0 3px;
    font-size: 10px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1.5px;
  border-color: $blue;
}

.form__field.amount-family {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: max-content;
    top: -9px;
    left: 5px;
    padding: 0 3px 0 3px;
    font-size: 10px;
    font-weight: 700;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1px;
  border-color: gray;
}

/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

//adrr
.form__field.addr:focus {
  &::placeholder {
    color: grey;
  }
}

//login
.form__field.email-login:empty:not(:focus) , .form__field.pass-login:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 7px;
    left: 10px;
    padding: 0;
    font-size: 12px;
    font-weight: normal;
  }
}

.form__field.email-login:focus , .form__field.pass-login:focus {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: #4b4bf9;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;

    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1.5px;
  border-color: #4b4bf9;
}

.form__field.email-login , .form__field.pass-login {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1px;
  border-color: gray;
}

//login-mobile
.form__field.email-login-mobile:empty:not(:focus) , .form__field.pass-login-mobile:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 7px;
    left: 10px;
    padding: 0;
    font-size: 12px;
    font-weight: normal;
  }
}

.form__field.email-login-mobile:focus , .form__field.pass-login-mobile:focus {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: white;
    height: max-content;
    top: -12px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;

    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1.5px;
  border-color: white;
}

.form__field.email-login-mobile , .form__field.pass-login-mobile {
  ~ .form__label {
    position: absolute;
    background-color: white;
    color: white;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;
    //z-index: 2;
    transition: 0.2s;
  }
  border-width: 1px;
  border-color: gray;
}
