/*1*/
.form__field-datepicker {
    height: 35px !important;
    padding: 10px;
    margin: 0 !important;
}
.react-datepicker-popper {
    z-index: 3 !important;
}
.react-datepicker-wrapper {
    width: 100% !important;
    max-width: 185px;
}

.PrivateRadioButtonIcon-root-5.PrivateRadioButtonIcon-checked-7 {
    color: #0bdefa !important;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #0bdefa !important;
}

.MuiSvgIcon-root {
    font-size: 18px !important;
    margin-right: -3px !important;
}

input[type='number'].amount {
    width: 90% !important;
    margin: 0;
    height: 35px;
    font-size: 14px !important;
}

textarea {
    width: 100% !important;
    height: 60px !important;
}

/*2*/
.label-width-in-form {
    width: 82px !important
}

input[type='text'].passNo {
    width: 100% !important;
    max-width: 185px;
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

/*3*/
/*Select*/
.css-yk16xz-control {
    border-style: solid !important;
    border-width: 1.0px !important;
    border-color: gray !important;
}
.css-2b097c-container.form2 {
    margin: 18px 0 !important;
    width: 100% !important;
    max-width: 185px;
}
.css-2b097c-container > div {
    height: 35px !important;
}
.css-2b097c-container > div > div {
    height: 35px !important;
}
.css-g1d714-ValueContainer {
    align-content: center !important;
}
div .css-26l3qy-menu {
    height: 135px !important;
}
div .css-26l3qy-menu > div{
    height: 135px !important;
}
.css-1pahdxg-control {
    border-style: solid !important;
    border-width: 1.5px !important;
    border-color: #0bdefa !important;
    box-shadow: 0 0 0 0 #0bdefa !important;
}

/*4*/
input[type='text'].name {
    width: 100% !important;
    max-width: 200px;
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

input[type='email'].email {
    background-color: white;
    width: 75% !important;
    max-width: 250px;
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

.grid-2-in-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

@media (max-width: 500px) {
    .grid-2-in-form {
        grid-template-columns: 1fr;
    }
    input[type='text'].name {
        width: 100% !important;
        max-width: 270px;
        margin: 0;
        padding: 10px;
        height: 35px;
        font-size: 14px !important;
    }

    input[type='email'].email {
        background-color: white;
        width: 100% !important;
        max-width: 270px;
        margin: 0;
        padding: 10px;
        height: 35px;
        font-size: 14px !important;
    }
}



/*Family*/
input[type='email'].email-passenger {
    background-color: white;
    width: 100% !important;
    /*max-width: 250px;*/
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

input[type="checkbox"] {
    background-color: red !important;
    border-radius: 20px;
    width: 10px;
    height: 15px;
}

input[type='number'].amount-family {
    width: 90% !important;
    margin: 0;
    height: 25px;
    font-size: 14px !important;
}

.grid-3-in-FamilyForm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.grid-2-in-FamilyForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

@media (max-width: 500px) {
    .grid-3-in-FamilyForm {
        grid-template-columns: 1fr;
    }
    .grid-2-in-FamilyForm {
        grid-template-columns: 1fr;
    }
}

.grid-4-in-family {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
