@import url(https://fonts.googleapis.com/css?family=Roboto);
#popup-login {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 6 !important;
}
#popup_inner {
    position: absolute;
    width: 700px;
    height: 434px;
    margin: auto;
}
.login-tab > .react-tabs__tab-list > li {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.login-tab > .react-tabs__tab-list > .react-tabs__tab--selected {
    margin-bottom: 1px !important;
    border-style: solid !important;
    border-color: #4b4bf9 !important;
    border-width: 0 !important;
    border-bottom-width: 2.5px !important;
}

input[type='email'].email-login , input[type='password'].pass-login , input[type='text'].pass-login  {
    background-color: rgba(255,255,255,0) !important;
    width: 100% !important;
    height: 20px;
    margin: 0;
    padding: 16px 12px !important;
    color: black;
    font-size: 12px !important;
    border-width: 1px !important;
    border-color: #ababab !important;
    border-radius: 5px !important;
}
input[type='email'].email-login:focus , input[type='password'].pass-login:focus , input[type='text'].pass-login:focus {
    border-width: 1px !important;
    border-color: #4b4bf9 !important;
}

#popup_inner_mobile {
    position: absolute;
    width: 100%;
    max-width: 390px;
    height: 100%;
    margin: auto;
}
.focused {
    display: none !important;
}
.login-tab-mobile > ul > #react-tabs-0 , .login-tab-mobile > ul > #react-tabs-2{
    background-color: rgba(0,0,0,0);
}
.login-tab-mobile > ul > .react-tabs__tab.react-tabs__tab--selected {
    color: white !important;
}
.login-tab-mobile > ul > .react-tabs__tab {
    color: #bbb !important;
}
.login-tab-mobile > .react-tabs__tab-list > li {
    margin-left: 10% !important;
    margin-right: 10% !important;
}
.login-tab-mobile > .react-tabs__tab-list > .react-tabs__tab--selected {
    margin-bottom: 1px !important;
    border-style: solid !important;
    border-color: #0bdefa !important;
    border-width: 0 !important;
    border-bottom-width: 2.5px !important;
}
input[type='email'].email-login-mobile , input[type='password'].pass-login-mobile , input[type='text'].pass-login-mobile {
    background-color: rgba(255,255,255,0) !important;
    width: 100% !important;
    height: 20px;
    margin: 0;
    padding: 16px 12px !important;
    color: white;
    font-size: 12px !important;
    border-width: 0 !important;
    border-bottom-width: 1.5px !important;
    border-color: white !important;
    border-radius: 0 !important;
}
input[type='email'].email-login-mobile:focus , input[type='password'].pass-login-mobile:focus , input[type='text'].pass-login-mobile:focus {
    border-bottom-width: 2px !important;
    border-color: #294956 !important;
}

.react-tabs__tab-list > li {
    background-color: rgba(0,0,0,0) !important;
}

#add {
    position: absolute;
    width: 90%;
    height: 95px;
    bottom : 2px;
    background-color: white;
    z-index: 5;
    box-shadow: 0 0 2px #0ad4f3;
}

#ios-prompt {
    position: absolute;
    width: 90%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 15px;
    padding-bottom: 5px;
    background-color: white;
    z-index: 7;
}

/* Global Styles */
:root {
  --primary-color: #dc3545;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

/*Added*/
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: local('Calibri'), url(/src/Font/calibri.ttf) format('ttf');
}

@font-face {
  font-family: 'Comic Sans MS';
  font-style: normal;
  font-weight: 400;
  src: local('Comic Sans MS'), url(/src/Font/comic.ttf) format('ttf');
}

@font-face {
  font-family: 'Times New Roman';
  font-style: normal;
  font-weight: 400;
  src: local('Times New Roman'), url(/src/Font/times.ttf) format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url(/src/Font/Roboto.woff2) format('woff2');
}

@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v16/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6BoWgz.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  font-family: Roboto;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1rem;
  line-height: 1.6;
  background-color: #c0e3e8 !important;
  color: #333;
}

a {
  color: #dc3545;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: #dc3545;
  color: var(--primary-color);
}

.text-dark {
  color: #333333;
  color: var(--dark-color);
}

.text-success {
  color: #28a745;
  color: var(--success-color);
}

.text-danger {
  color: #dc3545;
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
}

.grid-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
}

.btn {
  display: inline-block;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: #dc3545;
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: #333333;
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: #dc3545;
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: #28a745;
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ccc;
  font-size: 14px !important;
}

input[type='submit'],
button {
  font: inherit;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: #f4f4f4;
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 1rem;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: #f4f4f4;
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4,
  .grid-5,
  .grid-6 {
    grid-template-columns: 1fr;
  }

  .card {
    margin-bottom: 15px !important;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}

.form__group {
  position: relative;
  margin: 20px 0; }

.form__label {
  position: absolute;
  color: gray;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

.form__field {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field::-webkit-input-placeholder {
    color: transparent; }
  .form__field::-moz-placeholder {
    color: transparent; }
  .form__field:-ms-input-placeholder {
    color: transparent; }
  .form__field::-ms-input-placeholder {
    color: transparent; }
  .form__field::placeholder {
    color: transparent; }
  .form__field:placeholder-shown ~ .form__label {
    cursor: text;
    top: 3px;
    left: 10px;
    padding: 0;
    font-size: 14px;
    font-weight: 300; }

.form__field:focus {
  border-width: 1.5px;
  border-color: #0bdefa; }
  .form__field:focus ~ .form__label {
    position: absolute;
    background-color: white;
    color: #0bdefa;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field:not(:empty):focus {
  border-width: 1.5px;
  border-color: #0bdefa; }
  .form__field:not(:empty):focus ~ .form__label {
    position: absolute;
    background-color: white;
    color: #0bdefa;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field:not(:empty):not(:focus) {
  border-width: 1px;
  border-color: gray; }
  .form__field:not(:empty):not(:focus) ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

/*datepicker*/
.react-datepicker__input-container {
  position: relative;
  margin: 0; }

.form__label-datepicker {
  position: absolute;
  color: gray;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

.form__field-datepicker:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field-datepicker:empty:not(:focus)::-webkit-input-placeholder {
    color: transparent; }
  .form__field-datepicker:empty:not(:focus)::-moz-placeholder {
    color: transparent; }
  .form__field-datepicker:empty:not(:focus):-ms-input-placeholder {
    color: transparent; }
  .form__field-datepicker:empty:not(:focus)::-ms-input-placeholder {
    color: transparent; }
  .form__field-datepicker:empty:not(:focus)::placeholder {
    color: transparent; }
  .form__field-datepicker:empty:not(:focus):placeholder-shown ~ .form__label-datepicker {
    cursor: text;
    top: 6px;
    left: 10px;
    padding: 0;
    font-size: 14px;
    font-weight: 300; }

.form__field-datepicker:focus {
  border-width: 1.5px;
  border-radius: 3px;
  border-color: #0bdefa; }
  .form__field-datepicker:focus ~ .form__label-datepicker {
    position: absolute;
    background-color: white;
    color: #0bdefa;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
  .form__field-datepicker:focus::-webkit-input-placeholder {
    color: gray; }
  .form__field-datepicker:focus::-moz-placeholder {
    color: gray; }
  .form__field-datepicker:focus:-ms-input-placeholder {
    color: gray; }
  .form__field-datepicker:focus::-ms-input-placeholder {
    color: gray; }
  .form__field-datepicker:focus::placeholder {
    color: gray; }

.form__field-datepicker {
  border-width: 1px;
  border-color: gray; }
  .form__field-datepicker ~ .form__label-datepicker {
    position: absolute;
    background-color: white;
    color: grey;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

/*Amount*/
.form__field.amount:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field.amount:empty:not(:focus)::-webkit-input-placeholder {
    color: transparent; }
  .form__field.amount:empty:not(:focus)::-moz-placeholder {
    color: transparent; }
  .form__field.amount:empty:not(:focus):-ms-input-placeholder {
    color: transparent; }
  .form__field.amount:empty:not(:focus)::-ms-input-placeholder {
    color: transparent; }
  .form__field.amount:empty:not(:focus)::placeholder {
    color: transparent; }
  .form__field.amount:empty:not(:focus):placeholder-shown ~ .form__label {
    cursor: text;
    top: 5px;
    left: 10px;
    padding: 0;
    font-size: 14px;
    font-weight: 300; }

.form__field.amount:focus {
  border-width: 1.5px;
  border-color: #0bdefa; }
  .form__field.amount:focus ~ .form__label {
    position: absolute;
    background-color: white;
    color: #0bdefa;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field.amount {
  border-width: 1px;
  border-color: gray; }
  .form__field.amount ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

/*Amount-Family*/
.form__field.amount-family:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field.amount-family:empty:not(:focus)::-webkit-input-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus)::-moz-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):-ms-input-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus)::-ms-input-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus)::placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):placeholder-shown ~ .form__label {
    cursor: text;
    top: 3px;
    left: 7px;
    padding: 0;
    font-size: 12px;
    font-weight: 300; }

.form__field.amount-family:empty:not(:focus):disabled {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field.amount-family:empty:not(:focus):disabled::-webkit-input-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):disabled::-moz-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):disabled:-ms-input-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):disabled::-ms-input-placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):disabled::placeholder {
    color: transparent; }
  .form__field.amount-family:empty:not(:focus):disabled:placeholder-shown ~ .form__label {
    cursor: text;
    top: 3px;
    left: 7px;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0); }

.form__field.amount-family:focus {
  border-width: 1.5px;
  border-color: #0bdefa; }
  .form__field.amount-family:focus ~ .form__label {
    position: absolute;
    background-color: white;
    color: #0bdefa;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -9px;
    left: 5px;
    padding: 0 3px 0 3px;
    font-size: 10px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field.amount-family {
  border-width: 1px;
  border-color: gray; }
  .form__field.amount-family ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -9px;
    left: 5px;
    padding: 0 3px 0 3px;
    font-size: 10px;
    font-weight: 700;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none; }

.form__field.addr:focus::-webkit-input-placeholder {
  color: grey; }

.form__field.addr:focus::-moz-placeholder {
  color: grey; }

.form__field.addr:focus:-ms-input-placeholder {
  color: grey; }

.form__field.addr:focus::-ms-input-placeholder {
  color: grey; }

.form__field.addr:focus::placeholder {
  color: grey; }

.form__field.email-login:empty:not(:focus), .form__field.pass-login:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field.email-login:empty:not(:focus)::-webkit-input-placeholder, .form__field.pass-login:empty:not(:focus)::-webkit-input-placeholder {
    color: transparent; }
  .form__field.email-login:empty:not(:focus)::-moz-placeholder, .form__field.pass-login:empty:not(:focus)::-moz-placeholder {
    color: transparent; }
  .form__field.email-login:empty:not(:focus):-ms-input-placeholder, .form__field.pass-login:empty:not(:focus):-ms-input-placeholder {
    color: transparent; }
  .form__field.email-login:empty:not(:focus)::-ms-input-placeholder, .form__field.pass-login:empty:not(:focus)::-ms-input-placeholder {
    color: transparent; }
  .form__field.email-login:empty:not(:focus)::placeholder, .form__field.pass-login:empty:not(:focus)::placeholder {
    color: transparent; }
  .form__field.email-login:empty:not(:focus):placeholder-shown ~ .form__label, .form__field.pass-login:empty:not(:focus):placeholder-shown ~ .form__label {
    cursor: text;
    top: 7px;
    left: 10px;
    padding: 0;
    font-size: 12px;
    font-weight: normal; }

.form__field.email-login:focus, .form__field.pass-login:focus {
  border-width: 1.5px;
  border-color: #4b4bf9; }
  .form__field.email-login:focus ~ .form__label, .form__field.pass-login:focus ~ .form__label {
    position: absolute;
    background-color: white;
    color: #4b4bf9;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field.email-login, .form__field.pass-login {
  border-width: 1px;
  border-color: gray; }
  .form__field.email-login ~ .form__label, .form__field.pass-login ~ .form__label {
    position: absolute;
    background-color: white;
    color: gray;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field.email-login-mobile:empty:not(:focus), .form__field.pass-login-mobile:empty:not(:focus) {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .form__field.email-login-mobile:empty:not(:focus)::-webkit-input-placeholder, .form__field.pass-login-mobile:empty:not(:focus)::-webkit-input-placeholder {
    color: transparent; }
  .form__field.email-login-mobile:empty:not(:focus)::-moz-placeholder, .form__field.pass-login-mobile:empty:not(:focus)::-moz-placeholder {
    color: transparent; }
  .form__field.email-login-mobile:empty:not(:focus):-ms-input-placeholder, .form__field.pass-login-mobile:empty:not(:focus):-ms-input-placeholder {
    color: transparent; }
  .form__field.email-login-mobile:empty:not(:focus)::-ms-input-placeholder, .form__field.pass-login-mobile:empty:not(:focus)::-ms-input-placeholder {
    color: transparent; }
  .form__field.email-login-mobile:empty:not(:focus)::placeholder, .form__field.pass-login-mobile:empty:not(:focus)::placeholder {
    color: transparent; }
  .form__field.email-login-mobile:empty:not(:focus):placeholder-shown ~ .form__label, .form__field.pass-login-mobile:empty:not(:focus):placeholder-shown ~ .form__label {
    cursor: text;
    top: 7px;
    left: 10px;
    padding: 0;
    font-size: 12px;
    font-weight: normal; }

.form__field.email-login-mobile:focus, .form__field.pass-login-mobile:focus {
  border-width: 1.5px;
  border-color: white; }
  .form__field.email-login-mobile:focus ~ .form__label, .form__field.pass-login-mobile:focus ~ .form__label {
    position: absolute;
    background-color: white;
    color: white;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -12px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

.form__field.email-login-mobile, .form__field.pass-login-mobile {
  border-width: 1px;
  border-color: gray; }
  .form__field.email-login-mobile ~ .form__label, .form__field.pass-login-mobile ~ .form__label {
    position: absolute;
    background-color: white;
    color: white;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: -10px;
    left: 8px;
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: normal;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

/*Sidenav*/
.sidenav---sidenav-toggle---1KRjR {
    width: 64px !important;
    height: 55px !important;
}
.sidenav---sidenav---_2tBP {
    -webkit-transition-timing-function: linear !important;
            transition-timing-function: linear !important;
}
.sidenav---icon-bar---u1f02 {
    background-color: black !important; /*x*/
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    width: 15px !important; /*x*/
}
.sidenav---sidenav-navitem---uwIJ-:hover {
    background-color: #008abe !important; /*hover*/
}
.sidenav---sidenav-navitem---uwIJ-.logo {
    background-color: white !important; /*logo*/
}
.sidenav---sidenav-navitem---uwIJ-.logo:hover  {
    background-color: white !important; /*logo-hover*/
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u{
    background-color: #c0e3e8;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 220px !important;
}
#side {
    box-shadow: 0 4.5px 5px grey;
}

/*Header*/
.shadow-header {
    box-shadow: 0 7px 8px 0 rgba(0,0,0,.06);
}
.top-menu-item {
    color: #f4ac50 !important;
}
.top-menu-item:hover {
    color: #0bdefa !important;
    -webkit-transition: 0.35s !important;
    transition: 0.35s !important;
    text-rendering: optimizeLegibility !important;
}
.order-btn-in-header:hover {
    background-color: #003e56 !important;
    color: #f4ac50 !important;
    opacity: 1 !important;
    -webkit-transition: 0.35s !important;
    transition: 0.35s !important;
    text-rendering: optimizeLegibility !important;
}
.profileImg-in-header {
    width: 35px;
    height: 35px;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

/*Dashboard*/
.grid-2-dashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.col1-in-dashboard {
    width: 130%;
    margin : 0;
}
.col2-in-dashboard {
    position: relative;
    width: 68%;
    margin: 0 0 0 32%;
}

/*MyCard-Refund*/
.back-btn-in-refund {
    background-color: white;
    color: orange !important;
    font-size: 14px;
    height: 32px;
    width: 100px;
    margin: 0;
    margin-top: 10px;
    padding-top: 3.5px;
    border-style: solid;
    border-color: orange;
    border-width: 1.5px;
    border-radius: 15px;
}
.form-in-refund {
    padding-left: 20px;
}
.grid-2-for-btns-in-refund {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    justify-content: flex-end !important;
}
input[type='text'].refund {
    margin: 10px 0 0 5px !important;
    width: 200px;
    font-size: 14px !important;
    border-width: 1.5px;
    border-radius: 3px;
}
input[type='text'].refund:focus {
    border-color: #0bdefa;
}
input[type='number'].refund {
    margin: 0 5px 0 5px !important;
    height: 32px;
    width: 150px;;
    padding-left: 7px;
    font-size: 14px !important;
    border-style: solid;
    border-color: #ccc;
    border-width: 1.5px;
    border-radius: 3px;
}
input[type='number'].refund:focus {
    border-color: #0bdefa;
}
input[type='text'].phone-input-in-refund {
    margin: 10px 0 0 5px !important;
    width: 200px !important;
    font-size: 14px !important;
    border-width: 1.5px !important;
    border-radius: 3px;
}
input[type='text'].phone-input-in-refund:focus {
    border-color: #0bdefa;
}
input[type="text"].otp-input-in-refund {
    font-size: 25px !important;
    width: 160px !important;
    border-width: 0 !important;
    border-bottom-width: 2px !important;
    margin: 0 15px 10px 15px !important;
    text-align: center;
}
input[type="text"].otp-input-in-refund:focus {
    border-color: #0bdefa;
}
.form2-in-refund1 {
    width: 100%;
    margin-top: 15px;
}
.form2-in-refund2 {
    width: 100%;
}
.font-in-refund {
    font-size: 18px;
}
.font-in-refund2 {
    font-size: 17px;
}
.font-mobile-icon-in-refund {
    font-size: 50px;
}

/*MyCard-Lost*/
.MuiFormControlLabel-root > span {
    font-size: 14px !important;
    margin-right: 0 !important;
}

/*Reports*/
/*Pagination*/
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}
.pagination > li {
    display: inline;
}
.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: black;
    text-decoration: none;
    font-size: 13px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    z-index: 3;
    cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span {
    z-index: 2;
    cursor: pointer;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 2px;
    border-color: orange;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777;
    cursor: not-allowed;
    border-width: 0;
}
div.report:hover {
    background-color: #e7fcff !important;
}
div.report:hover > div > div > button {
    background-color: #e7fcff !important;
}

/*Converter*/
.select-in-converter > div > div.css-26l3qy-menu {
    height: 160px !important;
    width: 250px !important;
}
.select-in-converter > div > div.css-26l3qy-menu > div {
    height: 160px !important;
    width: 250px !important;
}
.select-in-converter > div > div {
    background-color: white;
    border-width: 0 !important;
}
.select-in-converter > div > div.css-yk16xz-control {
    background-color: rgba(0,0,0,0) !important;
}
.select-in-converter > div > div.css-1pahdxg-control {
    background-color: rgba(0,0,0,0) !important;
}
.select-in-converter > div > div > div > span.css-1okebmr-indicatorSeparator {
    background-color: rgba(0,0,0,0) !important;
}
.select-in-converter > div > div > div > div.css-1uccc91-singleValue {
    width: 270px;
}
.select-curr > div > div > div> .css-6q0nyr-Svg {
    color: #69c8ce !important;
}
.select-curr > div > div > div> .css-19bqh2r {
    color: #69c8ce !important;
}
#react-select-3-option-0 {
    background-color: white !important;
}
.css-9gakcf-option {
    background-color: #c0e3e8 !important;
}
.exchange {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.center-exchange {
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-2-in-converter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.shadowBox-in-converter {
    box-shadow: 0 0 5px 1.5px rgba(0, 0, 0, 0.10)
}
.shadowBox-btn-in-converter {
    box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.20);
}
.font-in-calc {
    font-size: 20px !important;
    font-family: 'Orbitron' !important;
}
.btn-calc {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

/*Orders*/
.shadowBox-in-orders {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.10)
}
.shadowLine {
    box-shadow: 0 1px 2px 0 rgba(204, 204, 204, 0.3)
}
.grid-5-in-orders {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
}
.grid-2-in-orders {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.grid-2-in-orders2 {
    grid-template-columns: 1fr;
}

/*Packages*/
.grid-2-in-packages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.input-in-packages:focus {
    border-color: #0bdefa !important;
    color: #0bdefa !important;
}

/*Settings*/
.col1-in-settings {
    position: relative;
    width: 125%;
}
.react-tabs {
    width: 100% !important;
}
.react-tabs__tab-list {
    width: 100% !important;
}
.react-tabs__tab-list > li {
    margin-left: 3.3% !important;
    margin-right: 3.3% !important;
}
.react-tabs__tab--selected {
    margin-bottom: 1px !important;
    border-style: solid !important;
    border-color: orange !important;
    border-width: 0 !important;
    border-bottom-width: 2px !important;
}
.react-tabs__tab {
    color: gray;
}
.react-tabs__tab.react-tabs__tab--selected > li {
    color: black !important;
}
input[type='text'].input-in-settings {
    font-size: 12px !important;
    width: 80%;
}
.all-btn-in-settings {
    background-color: white;
    color: orange;
    width: 200px;
    padding: 2px 0 2px 0;
    border-style: solid;
    border-color: orange;
    border-width: 2px;
    border-radius: 50px;
    font-size: 14px;
}
.MuiFormControlLabel-root.radio-in-curr-settings > span {
    font-size: 13px !important;
}
.key-icon-in-settings {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-right: 0;
}
input[type='password'].passInput-in-settings {
    width: 275px !important;
    font-size: 14px !important;
}
input[type='text'].passInput-in-settings {
    width: 275px !important;
    font-size: 14px !important;
}
.all-btn-in-settings-mobile {
    background-color: #f4ac50 !important;
    color: white !important;
    width: 100%;
    margin: auto;
    padding: 4px 0;
    border-style: solid;
    border-color: #f4ac50;
    border-width: 2px;
    border-radius: 10px;
    font-size: 14px;
}
.btn-in-edit-mobile {
    background-color: #f4ac50 !important;
    color: white !important;
    width: 88%;
    margin: auto;
    padding: 4px 0;
    border-style: solid;
    border-color: #f4ac50;
    border-width: 2px;
    border-radius: 10px;
    font-size: 14px;
}














@media (max-width: 768px) {
    /*Dashboard*/
    .grid-4-dashboard {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }
    .trans-shadow {
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.2)
    }
    /*MyCard*/
    .grid-2-myCard-mobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    /*B-menu*/
    a.active > svg > g.svg-bottomMenu {
        fill: #f4ac50 !important;
    }
    a.active > div {
        color: #f4ac50 !important;
    }
    /*Reports*/
    .box-shadow-in-reports {
        box-shadow: 0 0 4px 1px rgba(0,0,0,0.15)
    }
    .grid-2-reports {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
    }
    .grid-3-reports {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;
    }

    /*Converter*/
    .select-curr > div > div > div> .css-6q0nyr-Svg {
        color: #0bdefa !important;
        height: 18px;
    }

    /*Settings*/
    .radio-in-settings > label > span > span {
        color: white !important;
    }
    input[type='text'].input-in-edit {
        font-size: 12px !important;
        width: 220px !important;
    }
}

@media (max-width: 767px) {
    /*Header*/
    .profileImg-in-header {
        width: 40px;
        height: 40px;
    }
    /*MyCard-Refund*/
    .back-btn-in-refund {
        background-color: white;
        color: orange !important;
        font-size: 14px;
        height: 29px;
        width: 75px;
        margin: 0;
        margin-top: 10px;
        padding-top: 1.5px;
        border-style: solid;
        border-color: orange;
        border-width: 1.5px;
        border-radius: 15px;
    }
    .form-in-refund {
        padding-left: 0;
    }
    .form2-in-refund1 {
        width: 85%;
        margin: auto;
        margin-top: 40px;
    }
    .form2-in-refund2 {
        width: 85%;
        margin: auto;
    }
    .font-in-refund {
        font-size: 16px;
    }
    .font-in-refund2 {
        font-size: 14px;
    }
    .font-mobile-icon-in-refund {
        font-size: 40px;
    }
    input[type="text"].otp-input-in-refund {
        font-size: 20px !important;
        width: 160px !important;
        border-width: 0 !important;
        border-bottom-width: 2px !important;
        margin: 0 15px 10px 15px !important;
        text-align: center;
    }

    /*Converter*/
    .select-in-converter > div > div.css-26l3qy-menu {
        height: 150px !important;
        width: 225px !important;
    }
    .select-in-converter > div > div.css-26l3qy-menu > div {
        height: 150px !important;
        width: 225px !important;
    }
    .select-curr > div > div > div> .css-6q0nyr-Svg {
        color: #003e56 !important;
    }
}

@media (max-width: 950px) {
    /*Converter*/
    .grid-2-in-converter {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1000px) {
    /*Settings*/
    .col1-in-settings {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    /*Dashboard*/
    .grid-2-dashboard {
        grid-template-columns: 1fr;
    }
    .col1-in-dashboard {
        width: 100%;
        margin: auto;
    }
    .col2-in-dashboard {
        position: relative !important;
        width: 100% !important;
        margin: auto !important;
    }
}

@media (max-width: 1250px) {
    /*Dashboard*/
    /*.col2-in-dashboard {
        position: relative;
        width: 70%;
        margin: 0 0 0 31.5%;
    }*/
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    left: 18px;
    top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #f4ac50;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #f4ac50;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 35px;
    width: 25px;
    left: 14px !important;
    top: 20px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: white;
    width: 2px !important;
    height: 25px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #003e57;
    padding: 80px 15px;
    font-size: 13px;
    font-weight: normal;
}

/* Morph shape necessary with bubble or elastic ????? */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/*Add*/
.bm-item.menu-item {
    display: flex !important;
    align-content: center !important;
    color: white !important;
    padding: 10px 2px;
    margin-bottom: 15px;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    border-color: white;
}

.bm-menu-wrap {
    width: 270px !important;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
    position: absolute;
    margin: auto;
    background: white;
    border-radius: 10px;
}

._2Jtxm._35Ago ._2kL0S {
    height: 1.05rem;
    width: 1.05rem;
    background-color: #0bdefa;
    color: #0bdefa;
    -webkit-animation: _JAh3L 400ms ease forwards;
    animation: _JAh3L 400ms ease forwards;
    border-width: 0 !important;
}
._2Jtxm._35Ago ._2kL0S:hover {
    background-color: #0bdefa;
}
._2Jtxm._35Ago > div {
    color: #0bdefa !important;
}

._2Jtxm._2ZUAI ._2JvrO {
    background-color: #04ac0a;
    color: #04ac0a !important;
    -webkit-animation: _3SDnc 400ms ease-in-out forwards;
    animation: _3SDnc 400ms ease-in-out forwards;
    margin-top: 2px !important;
    border-width: 0 !important;
}
._2Jtxm._2ZUAI ._2JvrO:hover {
    background-color: #04ac0a;
}
._2Jtxm._2ZUAI ._2JvrO > svg {
    opacity: 0 !important;
}
._2Jtxm._2ZUAI > div {
    color: #04ac0a !important;
}

._2Jtxm::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1.8px;
    width: 100%;
    right: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: -1;
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #979797), color-stop(50%, #04ac0a));
    background: -webkit-linear-gradient(right, #979797 50%, #04ac0a 50%);
    background: linear-gradient(to left, #979797 50%, #04ac0a 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    -webkit-transition: background-position 200ms ease-in-out;
    transition: background-position 200ms ease-in-out;
}

._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
    background-color: #fbfeff;
    color: #fbfeff;
    border-style: solid;
    border-width: 2px;
    border-color: #979797;
    border-radius: 50%;
    height: 1.3rem;
    width: 1.3rem;
    line-height: 2.5rem;
    display: inline-flex;
    -webkit-transform-origin: center;
            transform-origin: center;
    align-items: center;
    justify-content: center;
}

._2kL0S, ._2JvrO {
    -webkit-transition: background-color 0ms ease;
    transition: background-color 0ms ease;
}

._2Jtxm ._1hzhf {
    color: #979797;
    position: absolute;
    top: -25px;
    left: 50%;
    width: 130px !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 12px !important;
    font-weight: bold;
}

@media (max-width: 500px) {
    ._2Jtxm ._1hzhf {
        color: #979797;
        position: absolute;
        top: -25px;
        left: 50%;
        width: 85px !important;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        font-size: 12px !important;
        font-weight: bold;
    }
}

._2pGos {
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

._hsN1w {
    background-color: #f4ac50;
    color: white;
}
._hsN1w:hover {
    background-color: #f4ac50;
    color: white;
    opacity: 0.8;
    /*box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);*/
}

._3CDiP {
    background-color: rgba(0,0,0,0);
    color: #f4ac50;
}
._3CDiP:hover {
    background-color: rgba(0,0,0,0);
    color: #f4ac50;
    opacity: 0.8;
    /*box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);*/
}

._53Ji7 {
    padding-top: 30px;
}
._2_g61 {
    padding-top: 10px;
    padding-bottom: 25px;
}

/**/

._53Ji7, ._53Ji7 * {
    box-sizing: border-box;
}
._1Lo2h {
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
._2Jtxm {
    list-style: none;
    position: relative;
    text-align: center;
    flex: 1 1 auto;
    position: relative;
}
._2Jtxm:first-child::before {
    content: none;
}
._2Jtxm ._1ixJ3 {
    display: block;
}
._2Jtxm._2ZUAI::before,
._2Jtxm._35Ago::before,
._2Jtxm._1CcaK::before {
    background-position: left bottom;
}
._2Jtxm._1CcaK ._2JvrO {
    color: white;
    background-color: #bb392d;
    -webkit-animation: _1ujce 350ms ease-in-out forwards;
    animation: _1ujce 350ms ease-in-out forwards;
}
._3uApM {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}
._2pGos._2RWmX {
    pointer-events: none;
    -webkit-filter: opacity(0);
    filter: opacity(0);
    cursor: default;
}

@-webkit-keyframes _JAh3L {
    0% { -webkit-transform: scale(1); transform: scale(1); }
    10% { -webkit-transform: scale(0.975); transform: scale(0.975); }
    40% { -webkit-transform: scale(1.4); transform: scale(1.4); }
    50%, 100% { -webkit-transform: scale(1.2); transform: scale(1.2); }
}

@keyframes _JAh3L {
    0% { -webkit-transform: scale(1); transform: scale(1); }
    10% { -webkit-transform: scale(0.975); transform: scale(0.975); }
    40% { -webkit-transform: scale(1.4); transform: scale(1.4); }
    50%, 100% { -webkit-transform: scale(1.2); transform: scale(1.2); }
}
@-webkit-keyframes _3SDnc {
    0% { -webkit-transform: scale(1.2); transform: scale(1.2); }
    35%, 100% { -webkit-transform: scale(1); transform: scale(1); }
}
@keyframes _3SDnc {
    0% { -webkit-transform: scale(1.2); transform: scale(1.2); }
    35%, 100% { -webkit-transform: scale(1); transform: scale(1); }
}
@-webkit-keyframes _1ujce {
    0% { -webkit-transform: translateX(0); transform: translateX(0); }
    15% { -webkit-transform: translateX(0.2rem); transform: translateX(0.2rem); }
    30%, 60% { -webkit-transform: translateX(-0.4rem); transform: translateX(-0.4rem); }
    45%, 75% { -webkit-transform: translateX(0.4rem); transform: translateX(0.4rem); }
    90% { -webkit-transform: translateX(-0.2rem); transform: translateX(-0.2rem); }
    100% { -webkit-transform: translateX(0); transform: translateX(0); }
}
@keyframes _1ujce {
    0% { -webkit-transform: translateX(0); transform: translateX(0); }
    15% { -webkit-transform: translateX(0.2rem); transform: translateX(0.2rem); }
    30%, 60% { -webkit-transform: translateX(-0.4rem); transform: translateX(-0.4rem); }
    45%, 75% { -webkit-transform: translateX(0.4rem); transform: translateX(0.4rem); }
    90% { -webkit-transform: translateX(-0.2rem); transform: translateX(-0.2rem); }
    100% { -webkit-transform: translateX(0); transform: translateX(0); }
}

/*1*/
.form__field-datepicker {
    height: 35px !important;
    padding: 10px;
    margin: 0 !important;
}
.react-datepicker-popper {
    z-index: 3 !important;
}
.react-datepicker-wrapper {
    width: 100% !important;
    max-width: 185px;
}

.PrivateRadioButtonIcon-root-5.PrivateRadioButtonIcon-checked-7 {
    color: #0bdefa !important;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #0bdefa !important;
}

.MuiSvgIcon-root {
    font-size: 18px !important;
    margin-right: -3px !important;
}

input[type='number'].amount {
    width: 90% !important;
    margin: 0;
    height: 35px;
    font-size: 14px !important;
}

textarea {
    width: 100% !important;
    height: 60px !important;
}

/*2*/
.label-width-in-form {
    width: 82px !important
}

input[type='text'].passNo {
    width: 100% !important;
    max-width: 185px;
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

/*3*/
/*Select*/
.css-yk16xz-control {
    border-style: solid !important;
    border-width: 1.0px !important;
    border-color: gray !important;
}
.css-2b097c-container.form2 {
    margin: 18px 0 !important;
    width: 100% !important;
    max-width: 185px;
}
.css-2b097c-container > div {
    height: 35px !important;
}
.css-2b097c-container > div > div {
    height: 35px !important;
}
.css-g1d714-ValueContainer {
    align-content: center !important;
}
div .css-26l3qy-menu {
    height: 135px !important;
}
div .css-26l3qy-menu > div{
    height: 135px !important;
}
.css-1pahdxg-control {
    border-style: solid !important;
    border-width: 1.5px !important;
    border-color: #0bdefa !important;
    box-shadow: 0 0 0 0 #0bdefa !important;
}

/*4*/
input[type='text'].name {
    width: 100% !important;
    max-width: 200px;
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

input[type='email'].email {
    background-color: white;
    width: 75% !important;
    max-width: 250px;
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

.grid-2-in-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

@media (max-width: 500px) {
    .grid-2-in-form {
        grid-template-columns: 1fr;
    }
    input[type='text'].name {
        width: 100% !important;
        max-width: 270px;
        margin: 0;
        padding: 10px;
        height: 35px;
        font-size: 14px !important;
    }

    input[type='email'].email {
        background-color: white;
        width: 100% !important;
        max-width: 270px;
        margin: 0;
        padding: 10px;
        height: 35px;
        font-size: 14px !important;
    }
}



/*Family*/
input[type='email'].email-passenger {
    background-color: white;
    width: 100% !important;
    /*max-width: 250px;*/
    margin: 0;
    padding: 10px;
    height: 35px;
    font-size: 14px !important;
}

input[type="checkbox"] {
    background-color: red !important;
    border-radius: 20px;
    width: 10px;
    height: 15px;
}

input[type='number'].amount-family {
    width: 90% !important;
    margin: 0;
    height: 25px;
    font-size: 14px !important;
}

.grid-3-in-FamilyForm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.grid-2-in-FamilyForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

@media (max-width: 500px) {
    .grid-3-in-FamilyForm {
        grid-template-columns: 1fr;
    }
    .grid-2-in-FamilyForm {
        grid-template-columns: 1fr;
    }
}

.grid-4-in-family {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

