/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    left: 18px;
    top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #f4ac50;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #f4ac50;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 35px;
    width: 25px;
    left: 14px !important;
    top: 20px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: white;
    width: 2px !important;
    height: 25px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #003e57;
    padding: 80px 15px;
    font-size: 13px;
    font-weight: normal;
}

/* Morph shape necessary with bubble or elastic ????? */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/*Add*/
.bm-item.menu-item {
    display: flex !important;
    align-content: center !important;
    color: white !important;
    padding: 10px 2px;
    margin-bottom: 15px;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    border-color: white;
}

.bm-menu-wrap {
    width: 270px !important;
}
