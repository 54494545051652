._2Jtxm._35Ago ._2kL0S {
    height: 1.05rem;
    width: 1.05rem;
    background-color: #0bdefa;
    color: #0bdefa;
    -webkit-animation: _JAh3L 400ms ease forwards;
    animation: _JAh3L 400ms ease forwards;
    border-width: 0 !important;
}
._2Jtxm._35Ago ._2kL0S:hover {
    background-color: #0bdefa;
}
._2Jtxm._35Ago > div {
    color: #0bdefa !important;
}

._2Jtxm._2ZUAI ._2JvrO {
    background-color: #04ac0a;
    color: #04ac0a !important;
    -webkit-animation: _3SDnc 400ms ease-in-out forwards;
    animation: _3SDnc 400ms ease-in-out forwards;
    margin-top: 2px !important;
    border-width: 0 !important;
}
._2Jtxm._2ZUAI ._2JvrO:hover {
    background-color: #04ac0a;
}
._2Jtxm._2ZUAI ._2JvrO > svg {
    opacity: 0 !important;
}
._2Jtxm._2ZUAI > div {
    color: #04ac0a !important;
}

._2Jtxm::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1.8px;
    width: 100%;
    right: 50%;
    transform: translateY(-50%);
    z-index: -1;
    background: linear-gradient(to left, #979797 50%, #04ac0a 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 200ms ease-in-out;
}

._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
    background-color: #fbfeff;
    color: #fbfeff;
    border-style: solid;
    border-width: 2px;
    border-color: #979797;
    border-radius: 50%;
    height: 1.3rem;
    width: 1.3rem;
    line-height: 2.5rem;
    display: inline-flex;
    transform-origin: center;
    align-items: center;
    justify-content: center;
}

._2kL0S, ._2JvrO {
    transition: background-color 0ms ease;
}

._2Jtxm ._1hzhf {
    color: #979797;
    position: absolute;
    top: -25px;
    left: 50%;
    width: 130px !important;
    transform: translateX(-50%);
    font-size: 12px !important;
    font-weight: bold;
}

@media (max-width: 500px) {
    ._2Jtxm ._1hzhf {
        color: #979797;
        position: absolute;
        top: -25px;
        left: 50%;
        width: 85px !important;
        transform: translateX(-50%);
        font-size: 12px !important;
        font-weight: bold;
    }
}

._2pGos {
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

._hsN1w {
    background-color: #f4ac50;
    color: white;
}
._hsN1w:hover {
    background-color: #f4ac50;
    color: white;
    opacity: 0.8;
    /*box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);*/
}

._3CDiP {
    background-color: rgba(0,0,0,0);
    color: #f4ac50;
}
._3CDiP:hover {
    background-color: rgba(0,0,0,0);
    color: #f4ac50;
    opacity: 0.8;
    /*box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);*/
}

._53Ji7 {
    padding-top: 30px;
}
._2_g61 {
    padding-top: 10px;
    padding-bottom: 25px;
}

/**/

._53Ji7, ._53Ji7 * {
    box-sizing: border-box;
}
._1Lo2h {
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
._2Jtxm {
    list-style: none;
    position: relative;
    text-align: center;
    flex: 1 1 auto;
    position: relative;
}
._2Jtxm:first-child::before {
    content: none;
}
._2Jtxm ._1ixJ3 {
    display: block;
}
._2Jtxm._2ZUAI::before,
._2Jtxm._35Ago::before,
._2Jtxm._1CcaK::before {
    background-position: left bottom;
}
._2Jtxm._1CcaK ._2JvrO {
    color: white;
    background-color: #bb392d;
    -webkit-animation: _1ujce 350ms ease-in-out forwards;
    animation: _1ujce 350ms ease-in-out forwards;
}
._3uApM {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}
._2pGos._2RWmX {
    pointer-events: none;
    -webkit-filter: opacity(0);
    filter: opacity(0);
    cursor: default;
}

@-webkit-keyframes _JAh3L {
    0% { transform: scale(1); }
    10% { transform: scale(0.975); }
    40% { transform: scale(1.4); }
    50%, 100% { transform: scale(1.2); }
}

@keyframes _JAh3L {
    0% { transform: scale(1); }
    10% { transform: scale(0.975); }
    40% { transform: scale(1.4); }
    50%, 100% { transform: scale(1.2); }
}
@-webkit-keyframes _3SDnc {
    0% { transform: scale(1.2); }
    35%, 100% { transform: scale(1); }
}
@keyframes _3SDnc {
    0% { transform: scale(1.2); }
    35%, 100% { transform: scale(1); }
}
@-webkit-keyframes _1ujce {
    0% { transform: translateX(0); }
    15% { transform: translateX(0.2rem); }
    30%, 60% { transform: translateX(-0.4rem); }
    45%, 75% { transform: translateX(0.4rem); }
    90% { transform: translateX(-0.2rem); }
    100% { transform: translateX(0); }
}
@keyframes _1ujce {
    0% { transform: translateX(0); }
    15% { transform: translateX(0.2rem); }
    30%, 60% { transform: translateX(-0.4rem); }
    45%, 75% { transform: translateX(0.4rem); }
    90% { transform: translateX(-0.2rem); }
    100% { transform: translateX(0); }
}
