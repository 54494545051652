.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
    position: absolute;
    margin: auto;
    background: white;
    border-radius: 10px;
}
