#popup-login {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 6 !important;
}
#popup_inner {
    position: absolute;
    width: 700px;
    height: 434px;
    margin: auto;
}
.login-tab > .react-tabs__tab-list > li {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.login-tab > .react-tabs__tab-list > .react-tabs__tab--selected {
    margin-bottom: 1px !important;
    border-style: solid !important;
    border-color: #4b4bf9 !important;
    border-width: 0 !important;
    border-bottom-width: 2.5px !important;
}

input[type='email'].email-login , input[type='password'].pass-login , input[type='text'].pass-login  {
    background-color: rgba(255,255,255,0) !important;
    width: 100% !important;
    height: 20px;
    margin: 0;
    padding: 16px 12px !important;
    color: black;
    font-size: 12px !important;
    border-width: 1px !important;
    border-color: #ababab !important;
    border-radius: 5px !important;
}
input[type='email'].email-login:focus , input[type='password'].pass-login:focus , input[type='text'].pass-login:focus {
    border-width: 1px !important;
    border-color: #4b4bf9 !important;
}

#popup_inner_mobile {
    position: absolute;
    width: 100%;
    max-width: 390px;
    height: 100%;
    margin: auto;
}
.focused {
    display: none !important;
}
.login-tab-mobile > ul > #react-tabs-0 , .login-tab-mobile > ul > #react-tabs-2{
    background-color: rgba(0,0,0,0);
}
.login-tab-mobile > ul > .react-tabs__tab.react-tabs__tab--selected {
    color: white !important;
}
.login-tab-mobile > ul > .react-tabs__tab {
    color: #bbb !important;
}
.login-tab-mobile > .react-tabs__tab-list > li {
    margin-left: 10% !important;
    margin-right: 10% !important;
}
.login-tab-mobile > .react-tabs__tab-list > .react-tabs__tab--selected {
    margin-bottom: 1px !important;
    border-style: solid !important;
    border-color: #0bdefa !important;
    border-width: 0 !important;
    border-bottom-width: 2.5px !important;
}
input[type='email'].email-login-mobile , input[type='password'].pass-login-mobile , input[type='text'].pass-login-mobile {
    background-color: rgba(255,255,255,0) !important;
    width: 100% !important;
    height: 20px;
    margin: 0;
    padding: 16px 12px !important;
    color: white;
    font-size: 12px !important;
    border-width: 0 !important;
    border-bottom-width: 1.5px !important;
    border-color: white !important;
    border-radius: 0 !important;
}
input[type='email'].email-login-mobile:focus , input[type='password'].pass-login-mobile:focus , input[type='text'].pass-login-mobile:focus {
    border-bottom-width: 2px !important;
    border-color: #294956 !important;
}

.react-tabs__tab-list > li {
    background-color: rgba(0,0,0,0) !important;
}

#add {
    position: absolute;
    width: 90%;
    height: 95px;
    bottom : 2px;
    background-color: white;
    z-index: 5;
    box-shadow: 0 0 2px #0ad4f3;
}

#ios-prompt {
    position: absolute;
    width: 90%;
    height: max-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 15px;
    padding-bottom: 5px;
    background-color: white;
    z-index: 7;
}
