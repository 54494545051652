/*Sidenav*/
.sidenav---sidenav-toggle---1KRjR {
    width: 64px !important;
    height: 55px !important;
}
.sidenav---sidenav---_2tBP {
    transition-timing-function: linear !important;
}
.sidenav---icon-bar---u1f02 {
    background-color: black !important; /*x*/
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    width: 15px !important; /*x*/
}
.sidenav---sidenav-navitem---uwIJ-:hover {
    background-color: #008abe !important; /*hover*/
}
.sidenav---sidenav-navitem---uwIJ-.logo {
    background-color: white !important; /*logo*/
}
.sidenav---sidenav-navitem---uwIJ-.logo:hover  {
    background-color: white !important; /*logo-hover*/
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u{
    background-color: #c0e3e8;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 220px !important;
}
#side {
    box-shadow: 0 4.5px 5px grey;
}

/*Header*/
.shadow-header {
    box-shadow: 0 7px 8px 0 rgba(0,0,0,.06);
}
.top-menu-item {
    color: #f4ac50 !important;
}
.top-menu-item:hover {
    color: #0bdefa !important;
    transition: 0.35s !important;
    text-rendering: optimizeLegibility !important;
}
.order-btn-in-header:hover {
    background-color: #003e56 !important;
    color: #f4ac50 !important;
    opacity: 1 !important;
    transition: 0.35s !important;
    text-rendering: optimizeLegibility !important;
}
.profileImg-in-header {
    width: 35px;
    height: 35px;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

/*Dashboard*/
.grid-2-dashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.col1-in-dashboard {
    width: 130%;
    margin : 0;
}
.col2-in-dashboard {
    position: relative;
    width: 68%;
    margin: 0 0 0 32%;
}

/*MyCard-Refund*/
.back-btn-in-refund {
    background-color: white;
    color: orange !important;
    font-size: 14px;
    height: 32px;
    width: 100px;
    margin: 0;
    margin-top: 10px;
    padding-top: 3.5px;
    border-style: solid;
    border-color: orange;
    border-width: 1.5px;
    border-radius: 15px;
}
.form-in-refund {
    padding-left: 20px;
}
.grid-2-for-btns-in-refund {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    justify-content: flex-end !important;
}
input[type='text'].refund {
    margin: 10px 0 0 5px !important;
    width: 200px;
    font-size: 14px !important;
    border-width: 1.5px;
    border-radius: 3px;
}
input[type='text'].refund:focus {
    border-color: #0bdefa;
}
input[type='number'].refund {
    margin: 0 5px 0 5px !important;
    height: 32px;
    width: 150px;;
    padding-left: 7px;
    font-size: 14px !important;
    border-style: solid;
    border-color: #ccc;
    border-width: 1.5px;
    border-radius: 3px;
}
input[type='number'].refund:focus {
    border-color: #0bdefa;
}
input[type='text'].phone-input-in-refund {
    margin: 10px 0 0 5px !important;
    width: 200px !important;
    font-size: 14px !important;
    border-width: 1.5px !important;
    border-radius: 3px;
}
input[type='text'].phone-input-in-refund:focus {
    border-color: #0bdefa;
}
input[type="text"].otp-input-in-refund {
    font-size: 25px !important;
    width: 160px !important;
    border-width: 0 !important;
    border-bottom-width: 2px !important;
    margin: 0 15px 10px 15px !important;
    text-align: center;
}
input[type="text"].otp-input-in-refund:focus {
    border-color: #0bdefa;
}
.form2-in-refund1 {
    width: 100%;
    margin-top: 15px;
}
.form2-in-refund2 {
    width: 100%;
}
.font-in-refund {
    font-size: 18px;
}
.font-in-refund2 {
    font-size: 17px;
}
.font-mobile-icon-in-refund {
    font-size: 50px;
}

/*MyCard-Lost*/
.MuiFormControlLabel-root > span {
    font-size: 14px !important;
    margin-right: 0 !important;
}

/*Reports*/
/*Pagination*/
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}
.pagination > li {
    display: inline;
}
.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: black;
    text-decoration: none;
    font-size: 13px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    z-index: 3;
    cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span {
    z-index: 2;
    cursor: pointer;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 2px;
    border-color: orange;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777;
    cursor: not-allowed;
    border-width: 0;
}
div.report:hover {
    background-color: #e7fcff !important;
}
div.report:hover > div > div > button {
    background-color: #e7fcff !important;
}

/*Converter*/
.select-in-converter > div > div.css-26l3qy-menu {
    height: 160px !important;
    width: 250px !important;
}
.select-in-converter > div > div.css-26l3qy-menu > div {
    height: 160px !important;
    width: 250px !important;
}
.select-in-converter > div > div {
    background-color: white;
    border-width: 0 !important;
}
.select-in-converter > div > div.css-yk16xz-control {
    background-color: rgba(0,0,0,0) !important;
}
.select-in-converter > div > div.css-1pahdxg-control {
    background-color: rgba(0,0,0,0) !important;
}
.select-in-converter > div > div > div > span.css-1okebmr-indicatorSeparator {
    background-color: rgba(0,0,0,0) !important;
}
.select-in-converter > div > div > div > div.css-1uccc91-singleValue {
    width: 270px;
}
.select-curr > div > div > div> .css-6q0nyr-Svg {
    color: #69c8ce !important;
}
.select-curr > div > div > div> .css-19bqh2r {
    color: #69c8ce !important;
}
#react-select-3-option-0 {
    background-color: white !important;
}
.css-9gakcf-option {
    background-color: #c0e3e8 !important;
}
.exchange {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.center-exchange {
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-2-in-converter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.shadowBox-in-converter {
    box-shadow: 0 0 5px 1.5px rgba(0, 0, 0, 0.10)
}
.shadowBox-btn-in-converter {
    box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.20);
}
.font-in-calc {
    font-size: 20px !important;
    font-family: 'Orbitron' !important;
}
.btn-calc {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

/*Orders*/
.shadowBox-in-orders {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.10)
}
.shadowLine {
    box-shadow: 0 1px 2px 0 rgba(204, 204, 204, 0.3)
}
.grid-5-in-orders {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
}
.grid-2-in-orders {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.grid-2-in-orders2 {
    grid-template-columns: 1fr;
}

/*Packages*/
.grid-2-in-packages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}
.input-in-packages:focus {
    border-color: #0bdefa !important;
    color: #0bdefa !important;
}

/*Settings*/
.col1-in-settings {
    position: relative;
    width: 125%;
}
.react-tabs {
    width: 100% !important;
}
.react-tabs__tab-list {
    width: 100% !important;
}
.react-tabs__tab-list > li {
    margin-left: 3.3% !important;
    margin-right: 3.3% !important;
}
.react-tabs__tab--selected {
    margin-bottom: 1px !important;
    border-style: solid !important;
    border-color: orange !important;
    border-width: 0 !important;
    border-bottom-width: 2px !important;
}
.react-tabs__tab {
    color: gray;
}
.react-tabs__tab.react-tabs__tab--selected > li {
    color: black !important;
}
input[type='text'].input-in-settings {
    font-size: 12px !important;
    width: 80%;
}
.all-btn-in-settings {
    background-color: white;
    color: orange;
    width: 200px;
    padding: 2px 0 2px 0;
    border-style: solid;
    border-color: orange;
    border-width: 2px;
    border-radius: 50px;
    font-size: 14px;
}
.MuiFormControlLabel-root.radio-in-curr-settings > span {
    font-size: 13px !important;
}
.key-icon-in-settings {
    transform: rotate(-45deg);
    margin-right: 0;
}
input[type='password'].passInput-in-settings {
    width: 275px !important;
    font-size: 14px !important;
}
input[type='text'].passInput-in-settings {
    width: 275px !important;
    font-size: 14px !important;
}
.all-btn-in-settings-mobile {
    background-color: #f4ac50 !important;
    color: white !important;
    width: 100%;
    margin: auto;
    padding: 4px 0;
    border-style: solid;
    border-color: #f4ac50;
    border-width: 2px;
    border-radius: 10px;
    font-size: 14px;
}
.btn-in-edit-mobile {
    background-color: #f4ac50 !important;
    color: white !important;
    width: 88%;
    margin: auto;
    padding: 4px 0;
    border-style: solid;
    border-color: #f4ac50;
    border-width: 2px;
    border-radius: 10px;
    font-size: 14px;
}














@media (max-width: 768px) {
    /*Dashboard*/
    .grid-4-dashboard {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }
    .trans-shadow {
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.2)
    }
    /*MyCard*/
    .grid-2-myCard-mobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    /*B-menu*/
    a.active > svg > g.svg-bottomMenu {
        fill: #f4ac50 !important;
    }
    a.active > div {
        color: #f4ac50 !important;
    }
    /*Reports*/
    .box-shadow-in-reports {
        box-shadow: 0 0 4px 1px rgba(0,0,0,0.15)
    }
    .grid-2-reports {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
    }
    .grid-3-reports {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;
    }

    /*Converter*/
    .select-curr > div > div > div> .css-6q0nyr-Svg {
        color: #0bdefa !important;
        height: 18px;
    }

    /*Settings*/
    .radio-in-settings > label > span > span {
        color: white !important;
    }
    input[type='text'].input-in-edit {
        font-size: 12px !important;
        width: 220px !important;
    }
}

@media (max-width: 767px) {
    /*Header*/
    .profileImg-in-header {
        width: 40px;
        height: 40px;
    }
    /*MyCard-Refund*/
    .back-btn-in-refund {
        background-color: white;
        color: orange !important;
        font-size: 14px;
        height: 29px;
        width: 75px;
        margin: 0;
        margin-top: 10px;
        padding-top: 1.5px;
        border-style: solid;
        border-color: orange;
        border-width: 1.5px;
        border-radius: 15px;
    }
    .form-in-refund {
        padding-left: 0;
    }
    .form2-in-refund1 {
        width: 85%;
        margin: auto;
        margin-top: 40px;
    }
    .form2-in-refund2 {
        width: 85%;
        margin: auto;
    }
    .font-in-refund {
        font-size: 16px;
    }
    .font-in-refund2 {
        font-size: 14px;
    }
    .font-mobile-icon-in-refund {
        font-size: 40px;
    }
    input[type="text"].otp-input-in-refund {
        font-size: 20px !important;
        width: 160px !important;
        border-width: 0 !important;
        border-bottom-width: 2px !important;
        margin: 0 15px 10px 15px !important;
        text-align: center;
    }

    /*Converter*/
    .select-in-converter > div > div.css-26l3qy-menu {
        height: 150px !important;
        width: 225px !important;
    }
    .select-in-converter > div > div.css-26l3qy-menu > div {
        height: 150px !important;
        width: 225px !important;
    }
    .select-curr > div > div > div> .css-6q0nyr-Svg {
        color: #003e56 !important;
    }
}

@media (max-width: 950px) {
    /*Converter*/
    .grid-2-in-converter {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1000px) {
    /*Settings*/
    .col1-in-settings {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    /*Dashboard*/
    .grid-2-dashboard {
        grid-template-columns: 1fr;
    }
    .col1-in-dashboard {
        width: 100%;
        margin: auto;
    }
    .col2-in-dashboard {
        position: relative !important;
        width: 100% !important;
        margin: auto !important;
    }
}

@media (max-width: 1250px) {
    /*Dashboard*/
    /*.col2-in-dashboard {
        position: relative;
        width: 70%;
        margin: 0 0 0 31.5%;
    }*/
}
